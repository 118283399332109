<template>
    <section>
     <div id="conceptid">
        <el-col :span="5">
            <el-divider>分类</el-divider>
            <el-menu unique-opened @select = "menuselect"  :default-active="activeindex" background-color = "">
                <el-submenu index="1">
                    <template slot="title">名词</template>
                    <el-menu-item index="责任人">责任人</el-menu-item>
                    <el-menu-item index="指令人">指令人</el-menu-item>
                </el-submenu>
                <el-submenu index="2">
                    <template slot="title">操作</template>
                    <el-menu-item index="新建">新建</el-menu-item>
                    <el-menu-item index="编辑">编辑</el-menu-item>
                    <el-menu-item index="指派">指派</el-menu-item>
                    <el-menu-item index="分解">分解</el-menu-item>
                    <el-menu-item index="取消">取消</el-menu-item>
                    <el-menu-item index="复制">复制</el-menu-item>
                    <el-menu-item index="审核">审核</el-menu-item>
                </el-submenu>
                <el-submenu index="3">
                    <template slot="title">任务类型</template>
                       <el-submenu index="3-1">
                           <template slot="title">日常工作</template>
                           <el-menu-item index="日常工作">日常工作</el-menu-item>
                           <el-menu-item index="外部协调">外部协调</el-menu-item>
                           <el-menu-item index="领导安排">领导安排</el-menu-item>
                       </el-submenu>
                        <el-menu-item index="跨部门协同">跨部门协同</el-menu-item>
                        <el-menu-item index="专项会议">专项会议</el-menu-item>
                        <el-menu-item index="例会项目">例会项目</el-menu-item>
                        <el-menu-item index="年度计划">年度计划</el-menu-item>
                </el-submenu>
            </el-menu>
        </el-col>
        <el-col :span="9" style=" padding-left: 20px;padding-right: 20px;">
            <el-divider>解释</el-divider>
                <div style="margin-top: 200px;margin-left: 90px;">
                    <span style="font-weight: bold;font-size: 16px;color: #000000">{{explain}}</span>
                </div>
        </el-col>
        <el-col :span="10" style=" padding-left: 20px;padding-right: 20px;">
            <el-divider>用例</el-divider>
             <div style="margin-top: 200px;margin-left: 70px;">
               <span style="font-weight: bold;font-size: 16px;color: #000000">{{usecase}}</span>
             </div>
        </el-col>
     </div>
    </section>
</template>
<script>
export default {
    data(){
        return{
            activeindex: "责任人",
            explain: "责任人是指某项任务的具体执行人。",
            usecase: "A：小明创建了一条任务,责任人选为本人,即小明为此项任务的执行人。"+
                     "B：小明创建了一条任务,责任人选为小丽,即小丽为此项任务的执行人。"
        }
    },
    methods: {
        menuselect(index){
           if(index == "责任人"){
               this.explain = "责任人是指某项任务的具体执行人。";
               this.usecase = "A：小明创建了一条任务,责任人选为本人,即小明为此项任务的执行人。"+
                              "B：小明创建了一条任务,责任人选为小丽,即小丽为此项任务的执行人。"
           }else if(index == "指令人"){
               this.explain = "指令人是指某项任务下达决策、要求的人。";
               this.usecase = "小明接到的领导小红的电话,小红要求小明去做一件事,小明创建了一条任务,此时小红即此项任务的指令人。"
           }else if(index == "新建"){
               this.explain = "创建一条含工作项目、工作描述、计划完成时间的任务。";
               this.usecase = "小明在当月有很多项工作要做,于是他将每一项工作录入到系统中,隔三差五记录着每项工作的推进程度和完成百分比,时间到了月末,小明看到自己的工作全部完成,并且回看了整个过程,他很开心。"
           }else if(index == "编辑"){
               this.explain = "在任务产生进度之前再次修改任务的工作项目、工作内容、计划完成时间。";
               this.usecase = "小明接到了一项工作任务,要求做一张宽高200*300的图片,他刚刚创建好了这项任务,此时需求方要求将图片尺寸改为500*800,于是他又重新编辑了一下工作描述。"
           }else if(index == "指派"){
               this.explain = "将某项任务直接移交给它人。";
               this.usecase = "小明现在比较忙碌,有7项工作任务要做,此时,小明发现有3项工作任务小丽完全胜任,于是小明将这3项工作任务指派给了小丽,小明轻松了许多。"
           }else if(index == "分解"){
               this.explain = "分解指某项任务需我和它人一起参与执行。"
               this.usecase = "小明有一项工作任务,这项工作的80%小明可以独立完成,剩余20%需要与小丽配合才能完成,于是小明分解给小丽一条任务。"
           }else if(index == "取消"){
               this.explain = 
               "将任务取消掉"+
                "1、若任务来源为新建且无子集,则直接取消。"+
                "2、若任务来源为被分解,需任务上层责任人审批后取消。"+
                "3、若任务存在子集,则子集一同取消直接取消。"
                this.usecase = "A：小明新建了一条任务,这条任务因故不需要做了,于是小明将任务取消了。"+
                               "B：小明新建了一条任务并分解给了小丽;此时小丽因故不能执行,于是小丽进行取消操作且经过小明知晓后小丽的任务取消了。"
                               "C：小明新建了一条任务并分解给了小丽;此时小明收到通知任务无需继续执行了,于是小明与小丽沟通后,小明将任务取消了。"
           }else if(index == "复制"){
               this.explain = "将任务的工作项目、描述、计划完成时间等内容复制再次作为新的任务。"
               this.usecase = "小明想新建一条任务,小明发现上次执行了一个和此次一样的任务,于是小明找到上次那条任务后直接复制了一条。"
           }else if(index == "审核"){
               this.explain = "任务执行前、执行中、执行后需相关人员进行审核确认。"
               this.usecase = "A：小明新建了某项任务,由于小明新建的这条任务比较重要,所以需要小明的领导小红审批,于是小红给审批通过了,小明可以去做这件事了。"+
                              "B：小明新建了一条任务并分解给了小丽,小丽将任务完成,小明检查完小丽的工作成果后很满意,小明给小丽审批通过了。"+
                              "C：小明新建了一条任务并指派给了小丽,小丽因故不能执行,于是小丽进行了取消操作,小明和小丽沟通后达成一致,小明审核通过并取消了小丽的任务。"
           }else if(index == "日常工作"){
               this.explain = "日常的工作任务,无需审批,自行录入即可执行,可选择与年度计划任务关联。"
               this.usecase = "无";
           }else if(index == "外部协调"){
               this.explain = "任务执行过程需外部资源协助的工作任务,新建后需上级审批后执行。"
               this.usecase = "无";
           }else if(index == "领导安排"){
               this.explain = "越级分配事项,执行人新建后需上级阅知审批后执行的工作任务。"
               this.usecase = "无";
           }else if(index == "跨部门协同"){
               this.explain = "跨部门协同任务指某些事项执行过程需与非本部门人员联动共同完成的任务。"
               this.usecase = "无";
           }else if(index == "专项会议"){
               this.explain = "专项会议任务是指针对或围绕一个项目展开会议,会议过程中产生决议及需执行落地的事项的任务。"
               this.usecase = "无";
           }else if(index == "例会项目"){
               this.explain = "例会项目是指具有固定时间周期的例行会议,如部门周会,会议过程中产生决议及需执行落地的事项的任务。"
               this.usecase = "无";
           }else{
               this.explain = "年度计划任务是指具备长周期、大方向,远目标等特点的工作任务,如在年度初期录入任务,年底总结进行总结。"
               this.usecase = "无";
           }
        }
    }
}
</script>
<style scoped>
#conceptid{
    height: 600px;
    /* height: calc(100vh - 350px); */
    /* background-color: #F0F8FF; */
}
.el-menu-item.is-active {
      background-color: #3370ff !important;
      color: #fff;
}
/deep/.el-submenu .el-menu-item{
    min-width: 0px;
}
</style>